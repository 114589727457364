<script>
export default {
  name: "classify",
  data(){
    return{
      apiUrl:{
        list: '/api/project/list',
        add: '/api/project/saveOrUpdate',
        dict: '/api/bxs/dict/',
      },
      queryParams:{
        cardType: 1, // 1,单联卡
        projectType: 2, // 1:兽残,2:农残
        detectionMethod: '1,2',
      },
      projectName:'',
      list: [],
      filterList:[],
      color: true,
      line: true,
      activateIndex: null,
      activateInfo: {},
      cardType:[],
      cardList:[],
      cardRadio: 1, // 1,单联卡
      projectType:[
        { label: '兽残', value: 1 },
        { label: '农残', value: 2 }
      ],
      methods:[
        { label: '比色法', value: 1 },
        { label: '消线法', value: 2 }
      ],
      projectShow: false,
      projectForm: {
        projectType: 2,
        detectionMethod:[],
      },
      projectRules: {
        projectName: [
          {required: true, message: "请输入项目名称", trigger: 'blur'},
        ]
      },
      isContextMenuVisible: false,
      menuTop: 0,
      menuLeft: 0,
    }
  },
  async mounted() {
    await this.getDict();
    await this.getList();
    // 监听全局的点击和滚轮事件
    document.addEventListener('click', this.handleDocumentClick);
    document.addEventListener('wheel', this.handleDocumentWheel);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleDocumentClick);
    document.removeEventListener('wheel', this.handleDocumentWheel);
  },
  methods: {
    getList() {
      this.postApiConfig(this.apiUrl.list,this.queryParams).then((res)=>{
        this.list = res.data
        if(this.projectName){
          this.filterList = this.list.filter((e) => {
            return e.projectName.indexOf(this.projectName) != -1;
          });
          this.activateIndex = this.filterList.findIndex(e => e.id === this.activateInfo.id);
        }else{
          this.filterList = res.data;
        }
      })
    },
    getDict(){
      this.getApiConfig(this.apiUrl.dict,'reagent_card_type').then((res)=>{
        this.cardType = res.data
        this.cardRadio = parseInt(this.cardType[0].dictValue)
      })
    },
    choiceType(index){
      this.activateIndex = index;
      this.activateInfo = this.filterList[index];
      this.$emit('projectName',this.filterList[index])
    },
    inputProjectName(){
      this.filterList = this.list.filter((e) => {
        return e.projectName.indexOf(this.projectName) != -1;
      });
      this.activateIndex = this.filterList.findIndex(e => e.id === this.activateInfo.id);
    },
    changeDetectionMethod(index) {
      console.log("index",index)
      console.log("color",this.color)
      console.log("line",this.line)
      if(this.color && this.line){
        this.queryParams.detectionMethod = '1,2'
      }else if(this.color){
        this.queryParams.detectionMethod = 1
      }else if(this.line){
        this.queryParams.detectionMethod = 2
      }else {
        this.queryParams.detectionMethod = 0
      }
      console.log(this.queryParams)
      this.getList();
      // 更新filteredList并强制更新视图
      this.$forceUpdate();
    },
    selectTab(index) {
      this.cardRadio = index;
      // this.filterList = this.list.filter(item => parseInt(item.cardType) === index);
      this.queryParams.cardType = index
      this.getList()
      this.activateIndex = '';
      this.activateInfo = {};
      this.$emit('projectName',{})
      this.$emit('cardType',index)
    },
    // 添加项目
    addProject(){
      this.projectShow = true;
      this.projectForm = {
        projectType: 2,
        cardType: this.cardRadio,
        settingValue: 0.7,
        detectionMethod: 1,
      }
    },
    submitProject(){
      this.$refs['projectForm'].validate((valid) => {
        if (valid) {
          console.log("projectForm",this.projectForm);
          this.$modal.loading("正在请求数据，请稍后...");
          this.postApiConfig(this.apiUrl.add,this.projectForm).then(()=>{
            this.$modal.closeLoading();
            this.projectShow = true;
            this.$message.success("添加成功");
            this.getList()
          }).catch(()=>{
            this.$modal.closeLoading();
          })
        } else {
          return false;
        }
      });
    },
    closeProject(){
      this.projectShow = false;
      this.projectForm = {
        projectType: 2,
        detectionMethod:[],
      };
      this.$refs['projectForm'].resetFields();
    },
    changeInput(){
      this.$forceUpdate()
    },
    // 显示右键菜单
    showContextMenu(event,index) {
      this.activateIndex = index;
      event.preventDefault(); // 阻止默认的右键菜单弹出
      this.isContextMenuVisible = true;
      this.menuTop = event.pageY; // 获取鼠标点击的位置
      this.menuLeft = event.pageX;
    },
    // 处理菜单选项点击
    menuAction(option) {
      this.activateIndex = '';
      console.log(`你选择了: ${option}`);
      this.isContextMenuVisible = false; // 关闭菜单
    },
    handleDocumentClick(event) {
      if (!this.$el.contains(event.target)) {
        this.isContextMenuVisible = false;
      }
    },
    handleDocumentWheel() {
      this.isContextMenuVisible = false;
    },
  }
}
</script>

<template>
  <div class="classify-page">
    <!-- 搜索 -->
    <div class="classify-search">
      <el-row>
        <el-col :span="16">
          <el-form ref="queryForm" :model="queryParams" label-position="left" label-width="80px">
            <el-form-item label="项目名称:">
              <el-input v-model="projectName" placeholder="请输入项目名称" @input="inputProjectName" clearable style="width: 100%;"></el-input>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" style="display: flex;justify-content: flex-end;align-content: center;">
<!--          <el-button icon="el-icon-plus" @click="addProject">添加项目</el-button>-->
        </el-col>
      </el-row>
    </div>
    <div class="classify-tab">
      <el-row>
        <el-col :span="16">
          <div class="card-tab">
            <el-radio-group v-model="cardRadio" @change="selectTab(cardRadio)" style="height: 40px;display: flex;align-items: center;flex-wrap: wrap;">
              <el-radio v-for="(item,index) in cardType" :key="index" :label="parseInt(item.dictValue)" style="height: 40px;display: flex;align-items: center;">{{ item.dictLabel }}</el-radio>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :span="8">
          <div style="width: 100%;height: 40px;display: flex;justify-content: center;align-items: center;gap: 0 1.875rem  /* 30/16 */;">
            <div class="color">
              <el-checkbox v-model="color" @change="changeDetectionMethod(1)">比色法</el-checkbox>
            </div>
            <div class="line">
              <el-checkbox v-model="line" @change="changeDetectionMethod(2)">消线法</el-checkbox>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="classify-content" v-if="filterList.length > 0">
<!-- @contextmenu.prevent="showContextMenu($event,index)" -->
      <div class="classify-content-item" v-for="(item,index) in filterList" :key="index" @click="choiceType(index)" :class=" {'activateItem': activateIndex === index} ">
        <div class="content-item" style="width: 100%;height: 100%">
          <span :style="{color: item.authType > 0 ? '#ff0000' : '#333333'}">{{ item.projectName }}</span>
          <span class="spotBox-a" v-show="item.detectionMethod.includes('1')"></span>
          <span class="spotBox-b" v-show="item.detectionMethod.includes('2')"></span>
        </div>
      </div>
    </div>
    <div class="classify-empty" v-else>
      <el-empty description="暂无检测项目，请联系管理员添加"></el-empty>
    </div>

    <el-dialog title="添加 - 检测项目" :visible.sync="projectShow" width="600px" :before-close="closeProject">
      <el-form :rules="projectRules" ref="projectForm" :model="projectForm" label-width="100px">
        <el-form-item label="项目名称" prop="projectName">
          <el-input v-model="projectForm.projectName" clearable placeholder="请输入项目名称" size="small"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeProject">取 消</el-button>
        <el-button type="primary" @click="submitProject">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 右键菜单组件，通过v-show控制显示隐藏 -->
    <div v-show="isContextMenuVisible" class="custom-context-menu" :style="{top: menuTop + 'px', left: menuLeft + 'px'}">
      <ul>
        <li @click="menuAction('option1')">修改</li>
        <li @click="menuAction('option2')">删除</li>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "./classify.css";
// 变量定义部分（通常放在文件顶部或其他配置区域）
$indicator-height: 7px; // 定义横条高度变量
$primary-color: #1D8EFF; // 定义主要颜色变量

.classify-page{
  width: 100%;
  display: flex;
  flex-direction: column;

  .classify-search{
    width: 100%;
    height: 62px;
  }

  .classify-tab {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    .card-tab{
      width: 100%;
      height: 40px;
      overflow-y: auto;
      scrollbar-width: thin;

      &::-webkit-scrollbar {
        width: 8px; /* 宽度 */
      }
      &::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.1); /* 轨道颜色 */
      }
      &::-webkit-scrollbar-thumb {
        background: rgba(0, 0, 0, 0.4); /* 滑块颜色 */
        border-radius: 4px; /* 圆角 */
      }
      &::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 0, 0, 0.6); /* 鼠标悬停时滑块颜色 */
      }
    }
  }
  .classify-content{
    width: 100%;
    flex: 1;
    padding: 5px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: flex-start;
    gap: 0.9375rem  /* 15/16 */ 1.5625rem  /* 25/16 */;

    /* 添加这一行以允许仅Y轴滚动 */
    overflow-y: auto;

    /* 下面是自定义滚动条样式，主要针对Webkit内核浏览器 */
    scrollbar-width: thin; /* Firefox */
    //scrollbar-color: rgba(0, 0, 0, 0.4) rgba(255, 255, 255, 0.2); /* Firefox */

    &::-webkit-scrollbar {
      width: 8px; /* 宽度 */
    }

    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.1); /* 轨道颜色 */
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.4); /* 滑块颜色 */
      border-radius: 4px; /* 圆角 */
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.6); /* 鼠标悬停时滑块颜色 */
    }

    .classify-content-item{
      width: 30%;
      height: 3.125rem  /* 50/16 */;
      border-radius: 0.625rem  /* 10/16 */;
      box-sizing: border-box;
      padding: 0.3125rem  /* 5/16 */;
      background-color: #fcfcfc;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 1.125rem  /* 18/16 */;
      color: #333333;
      cursor:pointer;
      transition: transform 0.3s ease; /* 0.3秒为过渡持续时间，ease为过渡速度曲线 */


      .content-item{
        text-align: center;
        line-height: 2.5rem  /* 40/16 */;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor:pointer;
        position: relative;

        .spotBox-a{
          position: absolute;
          right: 0.3125rem  /* 5/16 */;
          width: 0.625rem  /* 10/16 */;
          height: 0.625rem  /* 10/16 */;
          border-radius: 50%;
          top: 0.3125rem  /* 5/16 */;
          background-color: #00be1f;
        }
        .spotBox-b{
          position: absolute;
          right: 0.3125rem  /* 5/16 */;
          width: 0.625rem  /* 10/16 */;
          height: 0.625rem  /* 10/16 */;
          border-radius: 50%;
          bottom: 0.3125rem  /* 5/16 */;
          background-color: #1D8EFF;
        }
      }

      &:hover{
        transform: translateY(-0.1875rem  /* -3/16 */);
      }
    }

    .activateItem{
      background-color: #eef6ff;
      color: #0062c4;
      border: 1px solid #0062c4;
    }
  }

  .classify-empty{
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-content: center;
  }

  // 右键菜单组件
  .custom-context-menu {
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    list-style-type: none;
    padding: 5px;
    border-radius: 5px;
    box-sizing: border-box;
    min-width: 100px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 999; /* 确保菜单在最上层 */
  }

  .custom-context-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .custom-context-menu li {
    cursor: pointer;
    padding: 8px 10px;
    font-weight: 400;
    font-size: 1.125rem  /* 18/16 */;
  }

  .custom-context-menu li:hover {
    background-color: #eef6ff;
  }
}
</style>
